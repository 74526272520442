<template>
  <div class="filter-cumac" :class="{ active: editing }">
    <div v-if="editing">
      <v-popover
        ref="popover"
        popoverClass="popover-cumac"
        :placement="placement"
        offset="10px"
        :handleResize="true"
      >
        <template slot="popover">
          <div class="cumac">
            <b-form-group label="Cumac :">
              <b-form-select v-model="selectedFilter">
                <b-form-select-option value=""></b-form-select-option>
                <b-form-select-option value="=">Égale à</b-form-select-option>
                <b-form-select-option value="<>"
                  >Pas Égale à</b-form-select-option
                >
                <b-form-select-option value=">"
                  >Supérieur à</b-form-select-option
                >
                <b-form-select-option value="<"
                  >Inférieur à</b-form-select-option
                >
                <b-form-select-option value=">="
                  >Supérieur ou égale à</b-form-select-option
                >
                <b-form-select-option value="<="
                  >Inférieur ou égale à</b-form-select-option
                >
                <b-form-select-option value="8"
                  >Valeur comprise</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group v-if="selectedFilter && selectedFilter != 8">
              <b-form-input
                v-model="selectedCumac"
                type="number"
              ></b-form-input>
            </b-form-group>

            <div v-if="selectedFilter == 8">
              <b-form-group label="Enter">
                <b-form-input
                  v-model="selectedCumacOne"
                  type="number"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Et">
                <b-form-input
                  v-model="selectedCumacTwo"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="action">
            <b-button
              variant="outline-secondary"
              size="sm"
              @click="hundlePopoverCancel()"
            >
              Annuler
            </b-button>
            <b-button variant="success" size="sm" @click="hundlePopoverOk()">
              Enregistrer
            </b-button>
          </div>
        </template>
      </v-popover>
    </div>
    <div
      @click.prevent.stop="editing = true"
      class="icon"
      @click="ouvert"
      :class="{ iconrefactor: styleFilterHiddenCol }"
    >
      <font-awesome-icon class="filter-icon" :icon="['fas', 'filter']" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
Vue.component('v-popover', VPopover)
export default {
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    styleFilterHiddenCol: { required: false }
  },
  data() {
    return {
      selectedCumac: null,
      selectedFilter: null,
      selectedCumacOne: null,
      selectedCumacTwo: null,
      editing: false
    }
  },
  methods: {
    ...mapActions(['fetchUsersCommercialSedentaire']),
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
      this.selectedCumac = null
      this.editing = false
      this.selectedFilter = null
      this.selectedCumacOne = null
      this.selectedCumacTwo = null
    },
    async hundlePopoverOk() {
      const payload = []
      if (this.selectedFilter) {
        if (
          this.selectedFilter == 8 &&
          this.selectedCumacOne &&
          this.selectedCumacTwo
        ) {
          payload.push({
            glue: 'and',
            key: 'cumac',
            operateur: this.selectedFilter,
            keyLabel: 'Cumac',
            value: [this.selectedCumacOne, this.selectedCumacTwo],
            valueLabel:
              this.$options.filters.formatDate(this.selectedFilter) +
              '  ' +
              `entre
              ${this.selectedCumacOne} et
              ${this.selectedCumacTwo}
            `
          })
        } else if (this.selectedCumac) {
          payload.push({
            glue: 'and',
            key: 'cumac',
            operateur: this.selectedFilter,
            keyLabel: 'Cumac',
            value: this.selectedCumac,
            valueLabel:
              this.$options.filters.formatDate(this.selectedFilter) +
              '  ' +
              this.selectedCumac
          })
        }
      } else {
        payload.push({
          key: 'cumac',
          value: null,
          operateur: '='
        })
      }
      this.$emit('change', payload)
      this.hundlePopoverCancel()
    },
    ouvert() {
      this.$nextTick(() => {
        this.$refs['popover'].show()
      })
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersCommercialsSedentaires',
      'getProjectsUsersCommercialSedentaireLoading'
    ]),
    options: function() {
      return this.getProjectsUsersCommercialsSedentaires.map(item => {
        return {
          text: item.full_name,
          value: item.id
        }
      })
    }
  },
  filters: {
    formatDate: value => {
      switch (value) {
        case '=':
          return 'Égale à'
        case '<>':
          return 'Pas Égale à'
        case '>':
          return 'Supérieur à'
        case '<':
          return 'Inférieur à'
        case '>=':
          return 'Supérieur ou égale à'
        case '<=':
          return 'Inférieur ou égale à'
        case '8':
          return 'Valeur comprise'
        default:
          return ''
      }
    }
  },
  watch: {
    editing(value) {
      if (value) {
        if (
          (!this.getProjectsUsersCommercialsSedentaires ||
            !this.getProjectsUsersCommercialsSedentaires.length) &&
          !this.getProjectsUsersCommercialSedentaireLoading
        ) {
          this.fetchUsersCommercialSedentaire()
        }
        this.$nextTick(() => {
          this.$refs['popover'].show()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-cumac {
    opacity: 1;
    display: block !important;
    z-index: 2000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 20px 0 0;
      text-align: left;
      max-width: unset;
      width: 300px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .cumac {
        .form-group {
          margin: 0 16px 10px;
        }
      }

      .stages {
        margin: 0 16px 10px;
        .label {
          padding-bottom: calc(0.375rem + 1px);
          margin-bottom: 0;
          font-size: inherit;
          line-height: 1.5;
          color: #656565;
        }

        .options {
          display: table;
          width: 100%;
          overflow: hidden;
          margin-bottom: 4px;
          border-radius: 4px;
          table-layout: fixed;
          .stage {
            height: 28px;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            padding: 0;
            font-size: 12px;
            font-weight: 700;
            color: #fff;
            background: #08a742;
            position: relative;

            &:before {
              width: 20px;
              height: 24px;
              position: absolute;
              right: -11px;
              top: 2px;
              border-top: 3px solid #fff;
              border-right: 3px solid #fff;
              transform: scaleX(0.3) rotate(45deg);
              content: ' ';
              background: #08a742;
              cursor: pointer;
              z-index: 1;
            }

            input {
              width: auto;
              position: absolute;
              opacity: 0;
              cursor: pointer;
              border: none;
              padding: 0;
              box-shadow: none;
              margin-right: 3px;
            }

            &.active {
              background: #08a742;
            }
            &.active ~ label {
              background: #e5e5e5;
            }

            &.active ~ label:before {
              background: #e5e5e5;
            }
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 48px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
.filter-cumac {
  &.active {
    margin: -9px;
  }
  .icon {
    width: 16px;
    // border: 1px solid #d8d8d8;
    position: absolute;
    left: 75%;
    right: 0%;
    bottom: 15px;
    top: 23px;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
    &.iconrefactor {
      top: 15px;
    }
    .filter-icon {
      color: #c5c5c5;
      font-size: 13px;
      &:hover {
        background-color: transparent;
        color: #5e5e5e;
      }
    }
  }
}
</style>
